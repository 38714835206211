import moment from 'moment'
import { jsPDF } from 'jspdf'
import QRCode from 'qrcode'
/***********************************
 @Start Date & Time Function's
***********************************/
function isDate(value) {
  return value instanceof Date && !isNaN(value)
}
export function initServerDate(obj) {
  if (typeof obj !== 'object' || obj === null) return obj
  const stack = [{ obj }]
  while (stack.length > 0) {
    const { obj: currentObj } = stack.pop()
    for (let key in currentObj) {
      if (currentObj.hasOwnProperty(key)) {
        if (isDate(currentObj[key])) {
          const dateString = currentObj[key].toISOString().slice(0, 10)
          if (dateString !== filterDatetime(currentObj[key], "yy'-'mm'-'dd 24")) {
            currentObj[key] = filterDatetime(currentObj[key], "yy'-'mm'-'dd 24")
          }
        } else if (typeof currentObj[key] === 'object' && currentObj[key] !== null) {
          stack.push({ obj: currentObj[key] })
        }
      }
    }
  }
  return obj
}
export function isTimeDifferenceGreaterThan(time1, time2, difference, unit) {
  const date1 = new Date(time1)
  const date2 = new Date(time2)
  const differenceInMilliseconds = Math.abs(date2 - date1)
  let convertedDifference
  switch (unit.toLowerCase()) {
    case 'hour':
    case 'hours':
      convertedDifference = differenceInMilliseconds / (1000 * 60 * 60)
      break
    case 'minute':
    case 'minutes':
      convertedDifference = differenceInMilliseconds / (1000 * 60)
      break
    case 'second':
    case 'seconds':
      convertedDifference = differenceInMilliseconds / 1000
      break
    default:
      throw new Error('Invalid unit specified. Use "hour(s)", "minute(s)", or "second(s)".')
  }
  return convertedDifference > difference
}

export function getTimeAgo(timestamp) {
  if (!timestamp) {
    return null
  }

  const currentDate = moment()
  const providedDate = moment(timestamp)
  const duration = moment.duration(currentDate.diff(providedDate))

  const intervals = [
    { label: 'year', unit: 'years' },
    { label: 'month', unit: 'months' },
    { label: 'week', unit: 'weeks' },
    { label: 'day', unit: 'days' },
    { label: 'hour', unit: 'hours' },
    { label: 'minute', unit: 'minutes' },
    { label: 'second', unit: 'seconds' }
  ]

  for (const { label, unit } of intervals) {
    const count = duration.get(unit)
    if (count > 0) {
      return `${count} ${label}${count !== 1 ? 's' : ''} ago`
    }
  }

  return 'Just now'
}

export function filterDatetime(datetime, option, isCurrentYear = false, requestType = 'year') {
  if (isCurrentYear) {
    switch (requestType) {
      case 'second':
      case 'minute':
      case 'hour':
        return formatCurrentDateTime('LT')
      case 'day':
        return formatCurrentDateTime('dddd')
      case 'month':
        return formatCurrentDateTime('MMMM')
      case 'year':
        return formatCurrentDateTime('YYYY')
      case 'dayMonth':
        return formatCurrentDateTime('D MMMM')
      case 'monthYear':
        return formatCurrentDateTime('MMMM YYYY')
      case 'dayMonthYear':
        return formatCurrentDateTime('LL')
      case 'dayMonthYearTime':
        return formatCurrentDateTime('LLL')

      case "yy'-'mm'-'dd":
        return formatCurrentDateTime('YYYY-MM-DD')
      case "dd'-'mm'-'yy":
        return formatCurrentDateTime('DD-MM-YYYY')
      case "mm'-'dd'-'yy":
        return formatCurrentDateTime('MM-DD-YYYY')

      case "yy'-'mm'-'dd 12":
        return formatCurrentDateTime('YYYY-MM-DD hh:mm:ss A')
      case "yy'-'mm'-'dd 24":
        return formatCurrentDateTime('YYYY-MM-DD HH:mm:ss')
      case "dd'-'mm'-'yy 12":
        return formatCurrentDateTime('DD-MM-YYYY hh:mm:ss A')
      case "dd'-'mm'-'yy 24":
        return formatCurrentDateTime('DD-MM-YYYY HH:mm:ss')
      case "mm'-'dd'-'yy 12":
        return formatCurrentDateTime('MM-DD-YYYY hh:mm:ss A')
      case "mm'-'dd'-'yy 24":
        return formatCurrentDateTime('MM-DD-YYYY HH:mm:ss')

      case "yy'/'mm'/'dd":
        return formatCurrentDateTime('YYYY/MM/DD')
      case "dd'/'mm'/'yy":
        return formatCurrentDateTime('DD/MM/YYYY')
      case "mm'/'dd'/'yy":
        return formatCurrentDateTime('MM/DD/YYYY')

      case "yy'/'mm'/'dd 12":
        return formatCurrentDateTime('YYYY/MM/DD hh:mm:ss A')
      case "yy'/'mm'/'dd 24":
        return formatCurrentDateTime('YYYY/MM/DD HH:mm:ss')
      case "dd'/'mm'/'yy 12":
        return formatCurrentDateTime('DD/MM/YYYY hh:mm:ss A')
      case "dd'/'mm'/'yy 24":
        return formatCurrentDateTime('DD/MM/YYYY HH:mm:ss')
      case "mm'/'dd'/'yy 12":
        return formatCurrentDateTime('MM/DD/YYYY hh:mm:ss A')
      case "mm'/'dd'/'yy 24":
        return formatCurrentDateTime('MM/DD/YYYY HH:mm:ss')
      default:
        return 'N/A'
    }
  } else {
    switch (option) {
      case '12hrs':
        return formatDateTime(datetime, 'LT')
      case '24hr':
        return formatDateTime(datetime, 'HH:mm')
      case '24hrs':
        return formatDateTime(datetime, 'HH:mm:ss')
      case 'day':
        return formatDateTime(datetime, 'dddd')
      case 'month':
        return formatDateTime(datetime, 'MMMM')
      case 'year':
        return formatDateTime(datetime, 'YYYY')
      case 'dayMonth':
        return formatDateTime(datetime, 'D MMMM')
      case 'monthYear':
        return formatDateTime(datetime, 'MMMM YYYY')
      case 'dayMonthYear':
        return formatDateTime(datetime, 'LL')
      case 'dayMonthYearTime':
        return formatDateTime(datetime, 'LLL')

      case "yy'-'mm'-'dd":
        return formatDateTime(datetime, 'YYYY-MM-DD')
      case "dd'-'mm'-'yy":
        return formatDateTime(datetime, 'DD-MM-YYYY')
      case "mm'-'dd'-'yy":
        return formatDateTime(datetime, 'MM-DD-YYYY')

      case "yy'-'mm'-'dd 12":
        return formatDateTime(datetime, 'YYYY-MM-DD hh:mm:ss A')
      case "yy'-'mm'-'dd 24":
        return formatDateTime(datetime, 'YYYY-MM-DD HH:mm:ss')
      case "dd'-'mm'-'yy 12":
        return formatDateTime(datetime, 'DD-MM-YYYY hh:mm:ss A')
      case "dd'-'mm'-'yy 24":
        return formatDateTime(datetime, 'DD-MM-YYYY HH:mm:ss')
      case "mm'-'dd'-'yy 12":
        return formatDateTime(datetime, 'MM-DD-YYYY hh:mm:ss A')
      case "mm'-'dd'-'yy 24":
        return formatDateTime(datetime, 'MM-DD-YYYY HH:mm:ss')

      case "yy'/'mm'/'dd":
        return formatDateTime(datetime, 'YYYY/MM/DD')
      case "dd'/'mm'/'yy":
        return formatDateTime(datetime, 'DD/MM/YYYY')
      case "mm'/'dd'/'yy":
        return formatDateTime(datetime, 'MM/DD/YYYY')

      case "yy'/'mm'/'dd 12":
        return formatDateTime(datetime, 'YYYY/MM/DD hh:mm:ss A')
      case "yy'/'mm'/'dd 24":
        return formatDateTime(datetime, 'YYYY/MM/DD HH:mm:ss')
      case "dd'/'mm'/'yy 12":
        return formatDateTime(datetime, 'DD/MM/YYYY hh:mm:ss A')
      case "dd'/'mm'/'yy 24":
        return formatDateTime(datetime, 'DD/MM/YYYY HH:mm:ss')
      case "mm'/'dd'/'yy 12":
        return formatDateTime(datetime, 'MM/DD/YYYY hh:mm:ss A')
      case "mm'/'dd'/'yy 24":
        return formatDateTime(datetime, 'MM/DD/YYYY HH:mm:ss')
      default:
        return 'N/A'
    }
  }
}

function formatDateTime(datetime, format) {
  const formats = [
    'hh:mm:ss A',
    'hh:mm A',
    'HH:mm:ss',
    'HH:mm',
    'YYYY-MM-DD HH:mm:ss',
    'YYYY-MM-DD HH:mm',
    'YYYY-MM-DDTHH:mm:ss',
    'YYYY-MM-DDTHH:mm',
    'MM/DD/YYYY HH:mm:ss',
    'MM/DD/YYYY HH:mm',
    'DD-MM-YYYY HH:mm:ss',
    'DD-MM-YYYY HH:mm',
    'YYYY/MM/DD HH:mm:ss',
    'YYYY/MM/DD HH:mm'
  ]
  const date = moment(datetime, formats, false)
  return date.isValid() ? date.format(format) : null
}

function formatCurrentDateTime(format) {
  return moment().format(format)
}
export function generateDateDifference(daysToAdd) {
  let currentDate = new Date()
  let differenceInMillis = daysToAdd * 24 * 60 * 60 * 1000
  return new Date(currentDate.getTime() - differenceInMillis)
}
export function nestGroupsBy(arr, properties) {
  properties = Array.from(properties)
  if (properties.length === 1) {
    return groupBy(arr, properties[0])
  }
  const property = properties.shift()
  var grouped = groupBy(arr, property)

  for (let key in grouped) {
    grouped[key] = nestGroupsBy(grouped[key], Array.from(properties))
  }
  return grouped
}

export function groupBy(conversions, property) {
  return conversions.reduce((acc, obj) => {
    let key = obj[property]
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
    return acc
  }, {})
}

export function filterObject(
  obj,
  filterType = 'multi-checkbox-select2',
  isFilterSpecificField = false,
  filterSpecificField = null,
  filterSecondSpecifiedField = null
) {
  var filterData = []

  if (filterType === 'select') {
    switch (isFilterSpecificField) {
      case true:
        if (filterSpecificField && filterSecondSpecifiedField)
          filterData.push(
            parseInt([obj].map((x) => x[filterSpecificField] && x[filterSecondSpecifiedField]))
          )
        else filterSpecificField
        filterData.push(parseInt([obj].map((x) => x[filterSpecificField])))
        break
      case false:
        filterData.push(parseInt([obj].map((x) => x.ash_Id)))
        break
    }
  } else if (filterType === 'multi-checkbox-select2') {
    switch (isFilterSpecificField) {
      case true:
        if (filterSpecificField && filterSecondSpecifiedField)
          for (let index = 0; index < obj.length; index++)
            filterData.push(obj[index][filterSpecificField], obj[index][filterSecondSpecifiedField])
        else filterSpecificField
        for (let index = 0; index < obj.length; index++)
          filterData.push(obj[index][filterSpecificField])
        break
      case false:
        for (let index = 0; index < obj.length; index++) filterData.push(obj[index].ash_Id)
        break
    }
  }
  return filterData
}

export function filterObjectModified(
  obj,
  filterType = 'multi-select',
  isFilterSpecificField = false,
  filterSpecificField = null,
  filterSecondSpecifiedField = null
) {
  if (filterType === 'select') obj = [{ ...obj }]
  switch (isFilterSpecificField) {
    case true:
      if (filterSpecificField && filterSecondSpecifiedField)
        return obj
          .map((o) => {
            return [
              {
                [filterSpecificField]: o[filterSpecificField],
                [filterSecondSpecifiedField]: o[filterSecondSpecifiedField]
              }
            ]
          })
          .flat()

      return obj.map((o) => o[filterSpecificField])
    case false:
      return obj.map(({ ash_Id }) => ash_Id)
  }
}

export function getValueOrDefault(value) {
  if (value === null) {
    return ''
  } else {
    return value
  }
}

export function formatName(firstName, middleName, lastName) {
  if (!firstName) firstName = ''

  if (!middleName) middleName = ''

  if (!lastName) lastName = ''

  return firstName + ' ' + middleName + ' ' + lastName
}

export function combineColumns(combineKey, ...args) {
  return args.reduce((acc, val) => {
    if (val != null && val !== undefined) return `${acc} ${combineKey} ${val}`
    else return `${acc}`
  })
}

export function generateFilteredObject(isInverse, payload, keysToFilter) {
  let output = {}

  if (isInverse)
    output = Object.fromEntries(
      Object.entries(payload).filter(([key]) => !keysToFilter.includes(key))
    )
  else
    output = Object.fromEntries(
      Object.entries(payload).filter(([key]) => keysToFilter.includes(key))
    )

  return output ? output : null
}

export function replaceHyphenAndCapitalize(str) {
  return str.replace(/-(\w)/g, function (match, letter) {
    return ' ' + letter.toUpperCase()
  })
}
export function hyphenToCamelCase(str) {
  return str.replace(/-([a-z])/g, function (match, letter) {
    return letter.toUpperCase()
  })
}

export function renameKeyInObject(array, keyMap) {
  return array.map((object) => {
    const renamedObject = {}
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        if (keyMap.hasOwnProperty(key)) {
          renamedObject[keyMap[key]] = object[key]
        } else {
          renamedObject[key] = object[key]
        }
      }
    }
    return renamedObject
  })
}

export function filterSpecificKeys(array, keysToKeep) {
  return array.map((object) => {
    const filteredObject = {}
    for (const key in object) {
      if (object.hasOwnProperty(key) && keysToKeep.includes(key)) {
        filteredObject[key] = object[key]
      }
    }
    return filteredObject
  })
}

export function generateRowsPerPageOptions(totalCount) {
  const maxOption = Math.ceil(totalCount / 50) * 50
  const optionsCount = maxOption / 50
  return Array.from({ length: optionsCount }, (_, index) => (index + 1) * 50)
}

export async function parseUserAgent() {
  const userAgent = navigator.userAgent

  let browserInfo = 'Unknown Client Agent'
  let osInfo = 'Unknown OS'

  if (userAgent.includes('Chrome')) {
    browserInfo = 'Chrome'
  } else if (userAgent.includes('Firefox')) {
    browserInfo = 'Firefox'
  } else if (userAgent.includes('Safari')) {
    browserInfo = 'Safari'
  } else if (userAgent.includes('Edg') || userAgent.includes('Edge')) {
    browserInfo = 'Edge'
  } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
    browserInfo = 'Opera'
  } else if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
    browserInfo = 'Internet Explorer'
  }

  if (userAgent.includes('Windows')) {
    osInfo = 'Windows'
  } else if (userAgent.includes('Android')) {
    osInfo = 'Android'
  } else if (userAgent.includes('Linux')) {
    osInfo = 'Linux'
  } else if (userAgent.includes('Ubuntu')) {
    osInfo = 'Ubuntu'
  } else if (userAgent.includes('Mac OS X')) {
    osInfo = 'iOS'
  }

  return {
    browser: browserInfo,
    os: osInfo
  }
}

export async function generateUniqueID() {
  try {
    const { userAgent, language } = window.navigator
    const { width, height } = window.screen
    const uniqueID = `${userAgent}${language}${width}${height}`
    const hashedID =
      window.crypto && window.crypto.subtle ? await hashString(uniqueID) : simpleHash(uniqueID)
    return hashedID.slice(0, 100)
  } catch (error) {
    console.error('Error in generateUniqueID', error)
    throw error
  }
}

async function hashString(input) {
  const encoder = new TextEncoder()
  const data = encoder.encode(input)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  return hashArray.map((byte) => ('0' + byte.toString(16)).slice(-2)).join('')
}

function simpleHash(input) {
  let hash = 0
  for (let i = 0; i < input.length; i++) hash = (hash << 5) - hash + input.charCodeAt(i)
  return String(hash)
}

export function extractRGBColorFromStyle(style) {
  const match = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*\d+\.\d+)?\)/.exec(style)
  if (match) {
    const [, r, g, b] = match
    return `rgb(${r}, ${g}, ${b})`
  }
  return null
}

export function rgbToHex(rgbString) {
  console.log('----rgbString----', rgbString)
  if (typeof rgbString !== 'string') {
    return null
  }

  var match = rgbString.match(/\d+/g)
  if (match) {
    var r = parseInt(match[0])
    var g = parseInt(match[1])
    var b = parseInt(match[2])
    var hexadecimal = '#' + ((r << 16) + (g << 8) + b).toString(16).padStart(6, '0')
    return hexadecimal
  }
  return null
}

export function getPageDimensions(pageType) {
  const pageProperties = {
    A0: { dimensions: [841, 1189], margins: [25, 25, 25, 25] },
    A1: { dimensions: [594, 841], margins: [20, 20, 20, 20] },
    A2: { dimensions: [420, 594], margins: [15, 15, 15, 15] },
    A3: { dimensions: [297, 420], margins: [15, 15, 15, 15] },
    A4: { dimensions: [210, 297], margins: [15, 15, 15, 15] },
    A5: { dimensions: [148, 210], margins: [15, 15, 15, 15] },
    A6: { dimensions: [105, 148], margins: [10, 10, 10, 10] },
    A7: { dimensions: [74, 105], margins: [10, 10, 10, 10] },
    A8: { dimensions: [52, 74], margins: [5, 5, 5, 5] },
    A9: { dimensions: [37, 52], margins: [5, 5, 5, 5] },
    A10: { dimensions: [26, 37], margins: [5, 5, 5, 5] },
    T4: { dimensions: [80, 80], margins: [10, 10, 10, 10] }
  }

  const upperCasePageType = Object.keys(pageProperties)[pageType]

  if (Object.prototype.hasOwnProperty.call(pageProperties, upperCasePageType)) {
    return pageProperties[upperCasePageType]
  } else {
    return null
  }
}

export function searchSubstringInArrayOfObjects(arr, substring) {
  const matchingObjects = []

  for (const obj of arr) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const propertyValue = obj[key]

        if (typeof propertyValue === 'string' && propertyValue.includes(substring)) {
          matchingObjects.push(obj)
          break
        }
      }
    }
  }
  return matchingObjects
}

export function findObjectsWithSubstring(obj, substring) {
  const results = []

  function search(obj) {
    if (Array.isArray(obj)) {
      for (const item of obj) {
        search(item)
      }
    } else if (typeof obj === 'object') {
      let found = false
      for (const key in obj) {
        if (typeof obj[key] === 'string' && obj[key].includes(substring)) {
          found = true
          break
        }
        search(obj[key])
      }
      if (found) {
        results.push(obj)
      }
    }
  }

  search(obj)

  return results
}

export function generateFileTypeValue(fileType) {
  if (fileType === null || fileType === undefined) return null
  if (typeof fileType !== 'string') return null
  if (fileType.startsWith('image/')) return 1
  else if (fileType.startsWith('video/')) return 2
  else return 3
}

export function generatePDF(headerTemplate, tableTemplate, footerTemplate, pdfPayload) {
  const metadata = { title: pdfPayload.metaTitle }
  const doc = new jsPDF({
    orientation: pdfPayload.metaPageOrientation,
    unit: 'mm',
    format: (getPageDimensions(pdfPayload?.metaPageSize) || { dimensions: [210, 297] }).dimensions,
    putOnlyUsedFonts: true
  })

  const pageProperties = getPageDimensions(pdfPayload?.metaPageSize) || {
    margins: [15, 15, 15, 15]
  }

  doc.setDocumentProperties(metadata)

  const element = `${headerTemplate} ${tableTemplate} ${footerTemplate}`

  doc.html(element, {
    callback: function (doc) {
      doc.output('dataurlnewwindow', {
        filename: metadata.title,
        title: metadata.title
      })
    },
    margin: pageProperties.margins
  })
}

export function generateUniqueEditableColumn(array, newObj, id) {
  const objectId = newObj[id]
  const index = array.findIndex((obj) => obj[id] === objectId)
  if (index !== -1) array[index] = newObj
  else array.push(newObj)
  return array
}

export function formatTitleCase(input) {
  if (typeof input !== 'string') {
    return input
  }

  let result = ''
  let capitalizeNext = true

  for (let i = 0; i < input.length; i++) {
    const char = input[i]

    if (char === ' ') {
      capitalizeNext = true
      result += char
    } else {
      if (capitalizeNext) {
        result += char.toUpperCase()
        capitalizeNext = false
      } else {
        result += char.toLowerCase()
      }
    }
  }

  return result
}

export async function findMinMaxDates(arr) {
  if (!Array.isArray(arr) || arr.length === 0) return null

  const dates = arr.map((obj) => new Date(obj.ash_Date))

  const minDate = filterDatetime(new Date(Math.min(...dates)), 'dayMonthYear')
  const maxDate = filterDatetime(new Date(Math.max(...dates)), 'dayMonthYear')

  return { minDate, maxDate }
}
/**
 * Generates an image with the initials of a given name.
 * @param {string} name - The name to generate initials from.
 * @param {Object} options - Options for customizing the image.
 * @param {string} [options.shape='circle'] - The shape of the background ('circle', 'rectangle', 'square', 'triangle').
 * @param {number} [options.width=100] - The width of the canvas.
 * @param {number} [options.height=100] - The height of the canvas.
 * @param {string} [options.backgroundColor='#ffffff'] - The background color of the canvas.
 * @param {number} [options.borderWidth=1] - The width of the border.
 * @param {string} [options.borderColor='#ccc'] - The color of the border.
 * @param {string} [options.fontColor='#2cab52'] - The color of the text.
 * @param {string} [options.font='bold 30px Arial'] - The font style of the text.
 * @param {string} [options.alignment='center'] - The horizontal alignment of the text.
 * @param {string} [options.baseLine='middle'] - The vertical alignment of the text.
 * @param {number} [options.verticalOffset=-0.25] - Adjustment value to move the text upwards.
 * @returns {string} - Data URL of the generated image.
 */
export function generateAlphabetImage(name, options = {}) {
  const defaultOptions = {
    shape: 'circle',
    width: 100,
    height: 100,
    backgroundColor: '#ffffff',
    borderWidth: 1,
    borderColor: '#ccc',
    fontColor: '#2cab52',
    font: 'bold 30px Arial',
    alignment: 'center',
    baseLine: 'middle',
    verticalOffset: 0
  }

  options = { ...defaultOptions, ...options }

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  canvas.width = options.width
  canvas.height = options.height

  switch (options.shape) {
    case 'rectangle':
      ctx.fillStyle = options.backgroundColor
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      break
    case 'square': {
      const size = Math.min(canvas.width, canvas.height)
      ctx.fillStyle = options.backgroundColor
      ctx.fillRect((canvas.width - size) / 2, (canvas.height - size) / 2, size, size)
      break
    }
    case 'triangle':
      ctx.fillStyle = options.backgroundColor
      ctx.beginPath()
      ctx.moveTo(canvas.width / 2, 0)
      ctx.lineTo(canvas.width, canvas.height)
      ctx.lineTo(0, canvas.height)
      ctx.closePath()
      ctx.fill()
      break
    case 'circle':
    default:
      ctx.fillStyle = options.backgroundColor
      ctx.beginPath()
      ctx.arc(
        canvas.width / 2,
        canvas.height / 2,
        Math.min(canvas.width, canvas.height) / 2,
        0,
        Math.PI * 2
      )
      ctx.fill()
      break
  }

  ctx.strokeStyle = options.borderColor
  ctx.lineWidth = options.borderWidth
  ctx.stroke()

  ctx.font = options.font
  ctx.fillStyle = options.fontColor
  ctx.textAlign = options.alignment
  ctx.textBaseline = options.baseLine

  const initials = name
    .match(/\b(\w)/g)
    .join('')
    .toUpperCase()

  ctx.fillText(initials, canvas.width / 2, canvas.height / 2 + options.verticalOffset)

  return canvas.toDataURL('image/png')
}

export async function generatePrintWindow(
  options = {
    about: '',
    target: '',
    isNewTab: true
  }
) {
  try {
    const windowWidth = 800
    const windowHeight = 600
    const leftPosition = (window.screen.width - windowWidth) / 2
    const topPosition = (window.screen.height - windowHeight) / 2
    const windowTarget = `left=${leftPosition},top=${topPosition},width=${windowWidth},height=${windowHeight},toolbar=0,scrollbars=0,status=0`
    return window.open(options.about, options.target, options.isNewTab ? windowTarget : '')
  } catch (error) {
    console.error('Error in generatePrintWindow', error)
  }
}

export function convertCurrencyToWords(amount, format = '₹') {
  const ones = [
    '',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine',
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen'
  ]
  const tens = [
    '',
    '',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety'
  ]

  function convertThreeDigits(num) {
    let words = ''
    if (num >= 100) {
      words += ones[Math.floor(num / 100)] + ' Hundred '
      num %= 100
    }
    if (num >= 20) {
      words += tens[Math.floor(num / 10)] + ' '
      num %= 10
    }
    if (num > 0) {
      words += ones[num] + ' '
    }
    return words
  }

  function addUnit(words, unit, num) {
    if (num !== 0) {
      if (words !== '') {
        words += ' '
      }
      words += unit
      if (num > 1) {
        words += 's'
      }
    }
    return words
  }

  let words = ''
  const decimalPart = Math.round((amount % 1) * 100)
  amount = Math.floor(amount)

  if (amount === 0 && decimalPart === 0) {
    words = 'Zero'
  } else {
    if (format === '₹') {
      if (amount >= 10000000) {
        words += convertThreeDigits(Math.floor(amount / 10000000)) + ' Crore '
        amount %= 10000000
      }
      if (amount >= 100000) {
        words += convertThreeDigits(Math.floor(amount / 100000)) + ' Lakh '
        amount %= 100000
      }
      if (amount >= 1000) {
        words += convertThreeDigits(Math.floor(amount / 1000)) + ' Thousand '
        amount %= 1000
      }
    } else if (format === '$') {
      if (amount >= 1000000000) {
        words += convertThreeDigits(Math.floor(amount / 1000000000)) + ' Billion '
        amount %= 1000000000
      }
      if (amount >= 1000000) {
        words += convertThreeDigits(Math.floor(amount / 1000000)) + ' Million '
        amount %= 1000000
      }
      if (amount >= 1000) {
        words += convertThreeDigits(Math.floor(amount / 1000)) + ' Thousand '
        amount %= 1000
      }
    }
    words += convertThreeDigits(amount)
  }
  let decimalWords = ''
  if (decimalPart > 0) {
    decimalWords = convertThreeDigits(decimalPart)
    if (format === '₹') {
      decimalWords += ' Paisa'
    } else if (format === '$') {
      decimalWords += ' Cents'
    }
  }

  if (format === '₹') {
    words = addUnit(words, 'Crore', Math.floor(amount / 10000000))
    amount %= 10000000
    words = addUnit(words, 'Lakh', Math.floor(amount / 100000))
    amount %= 100000
    words = addUnit(words, 'Thousand', Math.floor(amount / 1000))
    amount %= 1000
    words = addUnit(words, 'Rupee', amount)
  } else if (format === '$') {
    words = addUnit(words, 'Billion', Math.floor(amount / 1000000000))
    amount %= 1000000000
    words = addUnit(words, 'Million', Math.floor(amount / 1000000))
    amount %= 1000000
    words = addUnit(words, 'Thousand', Math.floor(amount / 1000))
    amount %= 1000
    words = addUnit(words, 'Dollar', amount)
  }
  if (words === '') return `${decimalWords.replace(/ {2}/g, ' ')} Only`
  return `${words.replace(/ {2}/g, ' ')}${
    decimalPart > 0 ? ' and ' + decimalWords.replace(/ {2}/g, ' ') : ''
  } Only`
}

export async function generateQRCode(inputData, options = {}) {
  try {
    const defaultOptions = {
      width: 264,
      height: 264,
      margin: 2.5,
      correctionLevel: 'H',
      isTransparent: true,
      colors: {
        background: '#E0E0E0',
        dark: '#000000',
        margin: '#808080'
      }
    }

    options = { ...defaultOptions, ...options }
    const canvas = document.createElement('canvas')
    canvas.width = options.width
    canvas.height = options.height
    QRCode.toCanvas(canvas, inputData, {
      color: {
        dark: options.colors.dark,
        light: options.colors.background,
        margin: options.colors.margin
      },
      margin: options.margin,
      width: canvas.width,
      height: canvas.height,
      errorCorrectionLevel: options.correctionLevel,
      transparent: options.isTransparent
    })
    return canvas.toDataURL('image/png')
  } catch (error) {
    console.error('Error generating QR code:', error)
    throw error
  }
}
export function sanitizeInput(inputString, options = { regx1: /[^a-zA-Z0-9\-*]/g, replace1: ' ' }) {
  return inputString.replace(options.regx1, options.replace1)
}
export function generateFloat(baseNumber, decimalPoints = 1) {
  if (baseNumber === null || baseNumber === undefined) return null
  if (typeof decimalPoints !== 'number' || decimalPoints < 1 || decimalPoints > 3) return null
  if (typeof baseNumber !== 'number' || !isFinite(baseNumber)) return null
  const formattedNumber = baseNumber.toFixed(decimalPoints)
  return parseFloat(formattedNumber)
}
export function generateUniqueString(ID, Random, Name, Value) {
  ID = String(ID)
  Random = String(Random)
  Value = String(Value)
  let combinedString = `${ID}${Random}${Name}${Value}${new Date().getTime()}`
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
  }
  const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz'
  const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const numberChars = '0123456789'
  const specialChars = '@#$%&*'
  let lowercasePool = []
  let uppercasePool = []
  let numberPool = []
  let specialPool = []
  for (let char of combinedString) {
    if (lowercaseChars.includes(char) && lowercasePool.length < 3) {
      lowercasePool.push(char)
    } else if (uppercaseChars.includes(char) && uppercasePool.length < 3) {
      uppercasePool.push(char)
    } else if (numberChars.includes(char) && numberPool.length < 3) {
      numberPool.push(char)
    } else if (specialChars.includes(char) && specialPool.length < 3) {
      specialPool.push(char)
    }
  }
  function ensureType(pool, fallbackChar) {
    if (pool.length === 0) {
      pool.push(fallbackChar)
    }
  }
  ensureType(lowercasePool, 'a')
  ensureType(uppercasePool, 'Z')
  ensureType(numberPool, '1')
  ensureType(specialPool, '@')
  lowercasePool = shuffleArray(lowercasePool)
  uppercasePool = shuffleArray(uppercasePool)
  numberPool = shuffleArray(numberPool)
  specialPool = shuffleArray(specialPool)
  let finalPool = [...lowercasePool, ...uppercasePool, ...numberPool, ...specialPool]
  finalPool = shuffleArray(finalPool)
  while (finalPool.length < 9) {
    finalPool.push('x')
  }
  finalPool = shuffleArray(finalPool).slice(0, 9)
  let uniqueString = finalPool.join('')
  return uniqueString
}
