export default [
  {
    path: '/attendance',
    components: {
      navigationBar: () => import('@/layout/common/body/body-view.vue')
    },
    children: [
      {
        path: '',
        name: 'attendance',
        component: () => import('@/views/dynamic-card.vue'),
        meta: {
          pageTitle: 'Attendance',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'attendance-tool',
        name: 'attendanceTool',
        component: () => import('@/views/attendance/attendance-tool.vue'),
        meta: {
          pageTitle: 'Attendance Tool',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'attendance-settings',
        children: [
          {
            path: '',
            name: 'attendanceSettings',
            component: () => import('@/views/dynamic-card.vue'),
            meta: {
              pageTitle: 'Attendance Settings',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'attendance-general-settings',
            name: 'attendanceGeneralSettings',
            component: () =>
              import('@/views/attendance/attendance-settings/attendance-general-settings.vue'),
            meta: {
              pageTitle: 'Attendance General Settings',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'attendance-time-table',
            name: 'attendanceTimeTable',
            component: () =>
              import('@/views/attendance/attendance-settings/attendance-time-table.vue'),
            meta: {
              pageTitle: 'Attendance Time Table',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          },
          {
            path: 'attendance-shift',
            name: 'attendanceShift',
            component: () => import('@/views/attendance/attendance-settings/attendance-shift.vue'),
            meta: {
              pageTitle: 'Attendance Shift',
              requireAuthorization: true,
              permission: ['admin', 'employee']
            }
          }
        ]
      },
      {
        path: 'attendance-schedule',
        name: 'attendanceSchedule',
        component: () => import('@/views/attendance/attendance-schedule.vue'),
        meta: {
          pageTitle: 'Attendance Schedule',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'mark-student-attendance',
        name: 'markStudentAttendance',
        component: () => import('@/views/attendance/mark-student-attendance.vue'),
        meta: {
          pageTitle: 'Mark Student Attendance',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      },
      {
        path: 'mark-employee-attendance',
        name: 'markEmployeeAttendance',
        component: () => import('@/views/attendance/mark-employee-attendance.vue'),
        meta: {
          pageTitle: 'Mark Employee Attendance',
          requireAuthorization: true,
          permission: ['admin', 'employee']
        }
      }
    ]
  }
]
