import { usePermissionStore } from '@/stores/permission/permissionStore.js'
import { mapState, mapActions } from 'pinia'
import { filterDatetime } from '@/_helpers/data.utils.js'
const generateModuleActionPermission = {
  data() {
    return {
      audio: new Audio(),
      isRestrictConfirmButton: false,
      isRestrictButton: false,
      apiCountDown: 10,
      isModuleActionPermissionLoading: true,
      isModuleActionPermissionSuccess: false,
      moduleActionPermissionName: null,
      memoryUsageData: [],
      debounceTimer: null,
      debounceInputSearch: '',
      debounceDelay: 400
    }
  },
  computed: {
    ...mapState(usePermissionStore, ['getListModuleActionPermission']),
    loadModuleActionPermission() {
      console.log('Mixin loadModuleActionPermission->', this.getListModuleActionPermission)
      return this.getListModuleActionPermission.length === 1
        ? this.getListModuleActionPermission[0]
        : this.getListModuleActionPermission
    },

    generateFilteredPermissions() {
      console.log(
        '---generateFilteredPermissions--',
        this.getListModuleActionPermission.find(
          (item) => item.moduleName === this.moduleActionPermissionName
        )
      )
      return this.getListModuleActionPermission.find(
        (item) => item.moduleName === this.moduleActionPermissionName
      )
    }
  },
  methods: {
    ...mapActions(usePermissionStore, ['storeLoadModuleActionPermission']),
    initFilterDate(date, dateTimeFormat = "dd'-'mm'-'yy 12") {
      try {
        return filterDatetime(date, dateTimeFormat)
      } catch (error) {
        console.error('Error in initFilterDate', error)
      }
    },
    async listAllModuleActionPermission(moduleId) {
      this.isModuleActionPermissionLoading = true
      this.isModuleActionPermissionSuccess = false
      await this.storeLoadModuleActionPermission(moduleId)

      this.isModuleActionPermissionLoading = false
    },

    async initSoundNotification(amount) {
      try {
        this.audio.src = '/audio/payment_success.mp3'
        await this.audio.play()
        const synth = window.speechSynthesis
        const utterance = new SpeechSynthesisUtterance(
          `Payment received: ${this.$primevue.config.locale.currencySymbol}${amount}. Thank you!`
        )
        synth.speak(utterance)
      } catch (error) {
        console.error('Error in initSoundNotification', error)
      }
    },

    async initMemoryUsage() {
      let storageDetail = {}
      const memoryInfo = performance?.memory
      const mb = 1024 * 1024

      if ('storage' in navigator && 'estimate' in navigator.storage) {
        const { usage, quota } = await navigator.storage.estimate()
        const percentUsed = Math.round((usage / quota) * 100)
        const usageInMib = Math.round(usage / (1024 * 1024))
        const quotaInMib = Math.round(quota / (1024 * 1024))
        storageDetail = `${usageInMib} out of ${quotaInMib} MiB used (${percentUsed}%)`
      }
      this.memoryUsageData.push({
        routeName: this.$route.name,
        jsHeapSizeLimit: (memoryInfo?.jsHeapSizeLimit / mb).toFixed(2) + ' MB',
        totalJSHeapSize: (memoryInfo?.totalJSHeapSize / mb).toFixed(2) + ' MB',
        usedJSHeapSize: (memoryInfo?.usedJSHeapSize / mb).toFixed(2) + ' MB',
        timestamp: new Date().toLocaleTimeString(),
        storageDetail
      })
      return console.log(`\x1b[1m ${this.$options.name} MEMORY:\x1b[0m`, this.memoryUsageData)
    }
  }
}
export default generateModuleActionPermission
